<template>
  <master-layout
    :showContentHeader="false"
    :showHeader="false"
    :noPadding="true"
  >
    <div class="page">
      <div @click="closeModal" class="ion-padding text-18">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </div>

      <div
        class="text-align-center mb-4 mt-4 pb-4 px-4 text-color-primary font-bold"
      >
        Dettaglio
      </div>

      <div v-for="product in item.items" :key="product.name">
        <div class="item" v-if="product.visibleApp">
          <span class="font-bold text-14">{{ product.nome }} </span>

          <span v-if="product.prezzo > 0" class="text-14"
            >{{
              product.prezzo.toLocaleString("it", {
                style: "currency",
                currency: "EUR",
              })
            }}
          </span>
          <span v-else> Gratis</span>
          <span class="actual text-14"
            >{{ product.quantita }}
            {{ product.quantita == 1 ? "pezzo" : "pezzi" }}</span
          >
        </div>
      </div>
      <div class="last-button flex justify-content-center">
        <button
          class="p-d"
          :class="{
            'button-secondary': aggiungiDisabilitato,
            'button-primary': !aggiungiDisabilitato,
          }"
          @click="addOne"
          :disabled="aggiungiDisabilitato"
        >
          {{
            !aggiungiDisabilitato ? "Aggiungi al carrello" : "Non compatibile"
          }}
        </button>
      </div>
    </div>
  </master-layout>

  <!-- <ion-button expand="block" @click="closeModal"> Close</ion-button> -->
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  modalController,
  IonIcon,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue";
import { useStore } from "vuex";

export default {
  name: "singleBundle",
  components: {
    IonGrid,

    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    modalController,
    IonIcon,
  },

  props: {
    item: Object,
  },
  mixins: [ShoppingCartService],
  created() {
    this.currentCart = useStore().state.shoppingCart.currentCart;
  },
  data() {
    return {
      filter: "",
      currentCart: {},
      arrowBackOutline,
      counter: 0,
      loadingItems: false,
    };
  },
  methods: {
    addOne() {
      let currentItem = { idBoundle: this.item.id, ...this.item };
      currentItem.number = 1;
      this.addItem(currentItem);
      this.counter++;
      this.$router.push("/shopping-cart");
      this.closeModal();
    },
    closeModal() {
      modalController.dismiss();
    },
  },
  computed: {
    aggiungiDisabilitato() {
      if (this.currentCart.items.length == 0) return false;
      let firstItem;

      if (this.currentCart.items[0].isContovendita) {
        firstItem = this.currentCart.items[0];
      } else {
        firstItem = {
          ...this.currentCart.items[0],
          isContovendita: false,
        };
      }

      if (
        firstItem.isContovendita ===
        (this.item.isContovendita ? this.item.isContovendita : false)
      )
        return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: var(--client-ground2-color) !important;
  min-height: 100vh;
  padding: 2rem 1rem;
  .item {
    width: 90%;
    margin: auto;
    font-size: 14px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 6fr 3fr 3fr;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

ion-icon {
  padding-top: 5px;
  font-size: 20px;
}

.numero {
  min-width: 25px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.7rem;
  background: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
}

.counter {
  background: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 110px;
  padding: 0.5rem 1rem;
}

.last-button {
  width: 90%;
  margin: auto;
}
</style>
